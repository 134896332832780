@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      @apply text-text-primary;
    } 
  }

@font-face {
    font-family: 'sf-pro-display-medium';
    src: url('./static/fonts/SFPRODISPLAYMEDIUM.OTF');
}

@font-face {
    font-family: 'sf-pro-display-regular';
    src: url('./static/fonts/SFPRODISPLAYREGULAR.OTF');
}

@font-face {
    font-family: 'sf-pro-display-bold';
    src: url('./static/fonts/SFPRODISPLAYBOLD.OTF');
}

